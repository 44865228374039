import { formatDistanceToNow } from "date-fns";
import * as Yup from "yup";
import { SUPPORTED_FORMATS } from "../helpers/Constants";

export const NOSPACE = "No leading and trailing spaces allowed";

export const STATES_LIST = [
  { label: "Select State", value: "" },
  { label: "Alabama", value: "Alabama" },
  { label: "Alaska", value: "Alaska" },
  { label: "Arizona", value: "Arizona" },
  { label: "Arkansas", value: "Arkansas" },
  { label: "California", value: "California" },
  { label: "Colorado", value: "Colorado" },
  { label: "Connecticut", value: "Connecticut" },
  { label: "Delaware", value: "Delaware" },
  { label: "Florida", value: "Florida" },
  { label: "Georgia", value: "Georgia" },
  { label: "Hawaii", value: "Hawaii" },
  { label: "Idaho", value: "Idaho" },
  { label: "Illinois", value: "Illinois" },
  { label: "Indiana", value: "Indiana" },
  { label: "Iowa", value: "Iowa" },
  { label: "Kansas", value: "Kansas" },
  { label: "Kentucky", value: "Kentucky" },
  { label: "Louisiana", value: "Louisiana" },
  { label: "Maine", value: "Maine" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "Michigan", value: "Michigan" },
  { label: "Minnesota", value: "Minnesota" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Missouri", value: "Missouri" },
  { label: "Montana", value: "Montana" },
  { label: "Nebraska", value: "Nebraska" },
  { label: "Nevada", value: "Nevada" },
  { label: "New Hampshire", value: "New Hampshire" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "New Mexico", value: "New Mexico" },
  { label: "New York", value: "NewYork" },
  { label: "North Carolina", value: "North Carolina" },
  { label: "North Dakota", value: "North Dakota" },
  { label: "Ohio", value: "Ohio" },
  { label: "Oklahoma", value: "Oklahoma" },
  { label: "Oregon", value: "Oregon" },
  { label: "Pennsylvania", value: "Pennsylvania" },
  { label: "Rhode Island", value: "Rhode Island" },
  { label: "South Carolina", value: "South Carolina" },
  { label: "South Dakota", value: "South Dakota" },
  { label: "Tennessee", value: "Tennessee" },
  { label: "Texas", value: "Texas" },
  { label: "Utah", value: "Utah" },
  { label: "Vermont", value: "Vermont" },
  { label: "Virginia", value: "Virginia" },
  { label: "Washington", value: "Washington" },
  { label: "West Virginia", value: "West Virginia" },
  { label: "Wisconsin", value: "Wisconsin" },
  { label: "Wyoming", value: "Wyoming" },
];

export let DAYS = [
  { label: "Select Day of the Month", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
  { label: "11", value: 11 },
  { label: "12", value: 12 },
  { label: "13", value: 13 },
  { label: "14", value: 14 },
  { label: "15", value: 15 },
  { label: "16", value: 16 },
  { label: "17", value: 17 },
  { label: "18", value: 18 },
  { label: "19", value: 19 },
  { label: "20", value: 20 },
  { label: "21", value: 21 },
  { label: "22", value: 22 },
  { label: "23", value: 23 },
  { label: "24", value: 24 },
  { label: "25", value: 25 },
  { label: "26", value: 26 },
  { label: "27", value: 27 },
  { label: "28", value: 28 },
 
];

export const MONTHS = ["Select Month", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const MESSAGE = {
  PASSWORD: "Must be minimum 8  characters long and alphanumeric with at least 1 uppercase letter, 1 number and 1 special character",
  REQUIRED: "This field is required",
  RENEWALAMOUNT: "Please enter a valid renewal amount",
  AMOUNT: "Please enter a valid amount",
  FILE_SIZE: "Please enter a valid file size",
};
export const PASSWORDS_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/g;

// const pattern = /  +/g;
// const email = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,4}$/;
const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z ]{2,}))$/;
const complexEmailRegex = (val) => {
  if (email.test(val)) {
    if (val.match(/\+/g)?.length > 1) {
      // console.log("h5");
      return false;
    }
    if (val.includes(".+.")) {
      // console.log("h1");
      return false;
    } else if (val.includes(".+")) {
      // console.log("h2");
      return true;
    } else if (val.includes("+.")) {
      // console.log("h3");
      return true;
    } else if (val.includes("++")) {
      // console.log("h4");
      return false;
    } else {
      // console.log("h6");
      return true;
    }
  } else {
    // console.log("2", false);
    return false;
  }
};
// const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
// const patternTwoDigisAfterComma = /^[0-9]+(\.[0-9]{1,2})?$/;
export const DECIMALPATTERN = /^[0-9]+(\.[0-9]{1,2})?$/;

const only_spaces = /^(?!\s+$).*/;
export const VALIDATE = {
  FIRSTNAME: Yup.string().max(30, "Max length is 30").required(MESSAGE.REQUIRED).matches(only_spaces, "Invalid first name"),
  LASTNAME: Yup.string().max(30, "Max length is 30").required(MESSAGE.REQUIRED).matches(only_spaces, "Invalid last name"),
  // EMAIL: Yup.string().required(MESSAGE.REQUIRED).email("Invalid email address"),
  EMAIL: Yup.string()
    .required(MESSAGE.REQUIRED)
    .test("is-email", "Invalid email address", (val) => complexEmailRegex(val)),
  PASSWORD: Yup.string().required(MESSAGE.REQUIRED).matches(PASSWORDS_REGEX, MESSAGE.PASSWORD),
  CONFIRM_PASSWORD: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required(MESSAGE.REQUIRED),
  INSTITUTENAME: Yup.string().max(30, "Max length is 30").required(MESSAGE.REQUIRED).matches(only_spaces, "Invalid institute name"),
  EMAILTEMPLATENAME: Yup.string().max(100, "Max length is 100").required(MESSAGE.REQUIRED).matches(only_spaces, "Invalid name"),
  TITLE: Yup.string().required(MESSAGE.REQUIRED).matches(only_spaces, "Invalid title name"),
  PHONENO: Yup.string()
    .required(MESSAGE.REQUIRED)
    .matches(/^[0-9]{10}$/, "Invalid phone number"),
  ZIPCODE: Yup.string()
    .required(MESSAGE.REQUIRED)
    .matches(/^[0-9]{5}$/, "Invalid zip code"),
  ACRONYM: Yup.string().max(30, "Max length is 30").required(MESSAGE.REQUIRED).matches(only_spaces, "Invalid acronym"),
  STREET_ADDRESS: Yup.string().max(100, "Max length is 100").required(MESSAGE.REQUIRED).matches(only_spaces, "Invalid street address"),
  CITY: Yup.string().max(30, "Max length is 30").required(MESSAGE.REQUIRED).matches(only_spaces, "Invalid city name"),
  STATE: Yup.string().required(MESSAGE.REQUIRED),
  TERM_AND_CONDITION: Yup.boolean().oneOf([true], "You must accept Terms & Conditions and Privacy Policy"),
  ROLE: Yup.string().required(MESSAGE.REQUIRED),
  CHILD_NAME: Yup.string().required(MESSAGE.REQUIRED).max(30, "Max length is 30").matches(only_spaces, "Invalid child name"),
  RENEWAL_AMOUNT: Yup.number()
    .required(MESSAGE.REQUIRED)
    .typeError(MESSAGE.RENEWALAMOUNT)
    .positive(MESSAGE.RENEWALAMOUNT)
    .test(
      "is-decimal",
      // "The amount should be a decimal with maximum two digits after dot",
      "Please enter a valid renewal amount",
      (val) => {
        if (val !== undefined) {
          return DECIMALPATTERN.test(val);
        }
        return true;
      }
    )
    .max(999999999, MESSAGE.RENEWALAMOUNT),
  FILE_SIZE: Yup.number()
    .required(MESSAGE.REQUIRED)
    .typeError(MESSAGE.FILE_SIZE)
    .positive(MESSAGE.FILE_SIZE)
    .max(100, MESSAGE.RENEWALAMOUNT),
  RENEWAL_PERIOD: Yup.string().required(MESSAGE.REQUIRED),
  RENEWAL_NOTES: Yup.string().max(500, "Max length is 500").required(MESSAGE.REQUIRED).matches(only_spaces, "Invalid notes"),
  RENEWAL_DAY: Yup.string().required(MESSAGE.REQUIRED).matches(/[^0]/, MESSAGE.REQUIRED),
  MEMBERSHIP_STATUS: Yup.boolean().required(MESSAGE.REQUIRED),
  POST_TITLE: Yup.string().max(30, "Max length is 30").required(MESSAGE.REQUIRED).matches(only_spaces, "Invalid title"),
  FILEUPLOADED: Yup.mixed()
    .test("fileFormat", "Unsupported Format", (value) => {
      if (typeof value === "string") {
        return true;
      } else {
        return !value || (value && SUPPORTED_FORMATS.includes(value.type));
      }
    })
    .test("fileSize", "File too large (max size 5MB)", (value) => {
      // console.log(value);
      if (typeof value === "string" || typeof value === "undefined") {
        return true;
      } else {
        return value && value.size <= 5000000;
      }
    }),
  START_DATE: Yup.date().min(new Date(), "Invalid date & time").required(MESSAGE.REQUIRED),
  END_DATE: Yup.date().min(Yup.ref("start_time"), "End datetime is greater then start datetime").required(MESSAGE.REQUIRED),
  DESCRIPTION: Yup.string().max(500, "Max length is 500").matches(only_spaces, "Invalid description"),
  GROUP_TITLE: Yup.string().max(30, "Max length is 30").required(MESSAGE.REQUIRED).matches(only_spaces, "Invalid group name"),
  AMOUNT: Yup.number()
    .required(MESSAGE.REQUIRED)
    .typeError(MESSAGE.AMOUNT)
    .positive(MESSAGE.AMOUNT)
    .test("is-decimal", "Please enter a valid amount", (val) => {
      if (val !== undefined) {
        return DECIMALPATTERN.test(val);
      }
      return true;
    })
    .max(999999999, MESSAGE.AMOUNT),
  LOCATION: Yup.string().max(100, "Max length is 100").required(MESSAGE.REQUIRED).matches(only_spaces, "Invalid location"),
};

// export const REMOVE_SPECIAL_CHARS = (text) => text.replace(/[~`!#$%^&*_\-+={}|\\?'\/₹]/gm, "");

export const SELECTED_ITEM = {
  "&.Mui-selected": {
    backgroundColor: "#1f66fa54",
    "&:hover": {
      backgroundColor: "#1f66fa70",
    },
  },
  "&:hover": {
    backgroundColor: "#1f66fa70",
  },
};

export function TIME_AGO(date) {
  return formatDistanceToNow(new Date(date), { addSuffix: true });
}
